export const validationMessages = {
	'cpfCnpj - NOT_FOUND': 'Documento inválido',
	'cpf - INVALID_VALUE': 'CPF inválido',
	'phoneNumber - PHONENUMBER_DOES_NOT_VALIDATED': 'Usuário com cadastro incompleto no programa',
	'cnpj - INVALID_VALUE': 'CNPJ inválido',
	'email - EMAIL_DOES_NOT_VALIDATED': 'Usuário com cadastro incompleto no programa',
	'Divisions - NOT_FOUND': 'Divisão de negócio não encontrada(s)',
	'TypeOfGrouper - DUPLICATED': 'Já existe uma negociação para este produto',
	'Description - DUPLICATED': 'Já existe uma origem de negociação com este nome',
	'user - NOT_FOUND': 'Este usuário não possui cadastro',
	'Divisions - REQUIRED_FIELD': 'Divisão de negócio obrigatória',
	'ContractNumber - REQUIRED_FIELD': 'Número do contrato é um campo obrigatório',
	'TransferValidAfter - REQUIRED_FIELD': 'Data de transferência é um campo obrigatório',
}
