import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React, { Suspense, useState } from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment/min/moment-with-locales'
import Moment from 'react-moment'
import 'moment/locale/pt-br'
import 'moment/locale/es'

import { Route, Switch, Redirect } from 'react-router-dom'
import * as serviceWorker from './utils/serviceWorker'
import AuthLayout from 'pages/Auth'
import AdminLayout from 'pages/Admin'
import { PrivateRoute } from 'components/PrivateRoute.jsx'
import { Provider } from 'react-redux'
import { handleAuthCheck, history } from './utils'
import { ConnectedRouter } from 'connected-react-router'
import store from './redux/store'
import axios from 'axios'

import 'assets/scss/material-dashboard-pro-react.scss?v=1.5.0'
import 'utils/i18n'
import 'assets/icons/style.css'

import NetworkService from './utils/network-service'
import { GeneralProvider } from './GeneralContext/index'
import SnackDefault from './components/SnackDefault'
import AwardProviders from './Providers'
import ConfirmToken from './pages/Auth/ConfirmToken'
import ResetPWD from './pages/Auth/NewPassword'
import { AuthProvider } from './utils/authconfig'
import { useEffect } from 'react'
import Favicon from 'react-favicon'
import { GetThemeColorFromLocalStorage } from './redux/api/themeColors'
import { envs } from './redux/api/windowServerData'

NetworkService.setupInterceptors(store)

//configureFakeBackend();
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
axios.defaults.headers.common['appId'] = 'PRN1802'
// axios.defaults.headers.common['companyInternalName'] = 'eprodutor'

// axios.defaults.baseURL = envs.REACT_APP_GERMINI_API_URL
axios.defaults.baseURL = envs.REACT_APP_GERMINI_API_URL

/******************
 * MOMENT CONFIG
 ******************/
// Sets the moment instance to use.
Moment.globalMoment = moment

// Set the locale for every react-moment instance to French.

export function getRightLocale() {
	const lang = localStorage.getItem('i18nextLng')
	if (lang) {
		return localStorage.getItem('i18nextLng').toLowerCase()
	}
	return 'pt-BR'
}

Moment.globalLocale = getRightLocale()

// Set the output format for every react-moment instance.
Moment.globalFormat = 'DD MM YYYY'

// Set the timezone for every instance.
Moment.globalTimezone = 'America/Sao_Paulo'

// Use a <span> tag for every react-moment instance.
Moment.globalElement = 'span'

// Upper case all rendered dates.
Moment.globalFilter = d => {
	return d.toUpperCase()
}

/* It needs to find a better way as soon as possible */
function tryRemove(key) {
	if (localStorage.getItem(key) !== null) {
		localStorage.removeItem(key)
	}
}

window.onbeforeunload = () => {
	tryRemove('i18next_pt-BR-common')
	tryRemove('i18next_es-common')
	tryRemove('i18next_en-common')
}

const App = () => {
	// const [themeColor, setThemeColor] = useState(getThemeColorFromLocalStorage())
	const [snackStatus, setSnackStatus] = useState({
		open: false,
		severity: 'success',
		text: 'Operação realizada com sucesso !',
	})

	return (
		<GeneralProvider>
			<Favicon url={`${GetThemeColorFromLocalStorage?.images?.favicon.url}`} />
			<AwardProviders>
				<Provider store={store}>
					<SnackDefault
						snackStatus={snackStatus}
						handleCloseSnack={() =>
							setSnackStatus(prevState => ({
								...prevState,
								open: false,
							}))
						}
					/>
					<Suspense fallback={<div />}>
						<ConnectedRouter history={history}>
							<AuthProvider>
								<Switch>
									<Route path="/auth" component={AuthLayout} />
									<Route path="/confirmToken" component={ConfirmToken} />
									<Route path="/resetPassword" component={ResetPWD} />
									<PrivateRoute path="/" component={AdminLayout} />

									<Redirect from="/" to="/home" />
								</Switch>
							</AuthProvider>
						</ConnectedRouter>
					</Suspense>
				</Provider>
			</AwardProviders>
		</GeneralProvider>
	)
}

ReactDOM.render(<App />, document.getElementById('root'))

//#region serviceWorker

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()

//#endregion
